import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Link, graphql, useStaticQuery, navigate } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Spinner, Col, Form, Row, Badge } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './font-awesome'
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useForm } from '../hooks/useForm'
import { useTheme } from '../context/themeContext'
import {
  validateForm,
  validateEmail,
  validateContent,
  validatePhoneNumber
} from '../helpers/validateFormsFields'
import colegiosList from '../data/colegios'
import carrerasList from '../data/carreras'
import postForm from '../services/postForm'

export const Formulario = ({
  idForm,
  title,
  receiverGeneral,
  typeGeneral,
  description = ''
}) => {
  const queryForms = useStaticQuery(graphql`
    query {
      allStrapiForm {
        nodes {
          strapiId
          nameForm
          nameEventForm
          uniqueReceiver
          interest_receiver {
            option
            receiver
          }
          interest_type {
            option
            type
          }
          redirectFormPage {
            name
            slug
          }
          name {
            name
            placeholder
          }
          email {
            name
            placeholder
          }
          phone {
            name
            placeholder
          }
          etiqueta {
            code
          }
          bodyForm {
            strapi_component
            name
            dropdownOptions {
              option
            }
            dropdownOptionsType {
              name
              interest_type {
                option
                type
              }
            }
            placeholder
            required
          }
          event {
            description
            name
            idEvent
            attendeeTypeId
            apiKey
            BuildingName
            BuildingUrl
            Items {
              name
              value
              tooltipItem
              wiseCode
            }
          }
        }
      }
    }
  `)

  // Dark Mode
  const { theme } = useTheme()

  // Seteando el nombre del Evento para que no se repitan
  let eventName = null
  let count = 0

  // Instanciando el Tooltip de Eventos
  const [ showTooltip, setShowTooltip ] = useState(false);
  
  // Instanciando codigo de pais
  const [ phoneNumber, setPhoneNumber ] = useState("+54");

  const [ validated, setValidated ] = useState(false)
  const [ values, handleInputChange, reset ] = useForm({})

  const [ loading, setLoading ] = useState(false)
  
  const { allStrapiForm: allForms } = queryForms
  
  const formFound = allForms.nodes?.find(({ strapiId }) => strapiId === idForm)
  const { name, email, phone, anioEgreso, colegio, dni, selectType, ubicacion, ...rest } = values

  // Icons del componente MultipleCareer
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
  // Seteando variable del codigo pais 
  const [ phonePais, setPhonePais ] = useState('+54')
  
  // Cambiando codigo pais al seleccionar otra bandera
  useEffect(() => {
    setPhonePais(phoneNumber) 
    values.phone = undefined
    valuesRequired.phone = phoneNumber
  }, [phoneNumber]);

  // Ubicacion
  const ubicacionExists = formFound?.bodyForm?.some(field => field.strapi_component === 'form.ubicacion');
  
  useEffect(() => {
    // Seteando la lista de carreras segun ubicación
    if (ubicacion === 'Córdoba') {
      setFilteredCareers(carrerasList.filter(carrera => carrera.cordoba));
    } else if (ubicacion === 'UCC Rio Cuarto') {
      setFilteredCareers(carrerasList.filter(carrera => carrera.rioCuarto));
    } else {
      setFilteredCareers([]);
    }

    setSelectedCareers([]); // Reseteo del selectedCareers
  }, [ubicacion]);

  // Multiple Careers
  const [ filteredCareers, setFilteredCareers ] = useState([]); // Estado para las carreras filtradas
  const [ selectedCareers, setSelectedCareers ] = useState([]); // Estado para las carreras seleccionadas

  const handleOptionChange = (event, value) => {
    setSelectedCareers(value);
  };

  // Definimos el type de Wise a utilizar en el selectType
  function definedType() {
    for (let i = 0; i < formFound?.bodyForm?.length; i++) {   // Recorremos el bodyForm
      if (formFound.bodyForm[i].strapi_component === 'form.select-type') {
        const select = formFound.bodyForm[i]
        for (let j = 0; j < select?.dropdownOptionsType?.length; j++) {   // Recorremos el DropdownOptionsType
          if (select?.dropdownOptionsType[j].interest_type?.option === selectType) {return select?.dropdownOptionsType[j].interest_type} 
        }
      }
    }
  }

  // valuesRequired para la validación visual
  const valuesRequired = {
    agent: receiverGeneral ? receiverGeneral : formFound?.interest_receiver?.receiver,
    etiqueta: formFound?.etiqueta?.map((e) => e.code),
    type: typeGeneral ? typeGeneral : formFound?.interest_type,
    subject: formFound?.nameForm,
    description: description,
    name,
    email,
    phone: phone !== undefined ?  phone : phoneNumber,
    dni,
    colegio: colegio,
    anioEgreso: anioEgreso,
    multipleCareer: selectedCareers.length === 0 ? null : selectedCareers,
    ubicacion,
    selectType: definedType(),
    content: rest,
    isEvent: (formFound?.event?.length) ? true : false,
    event: (formFound?.event?.length) ? rest.event : null,
    attendeeTypeIds: (formFound?.event?.length) ? formFound?.event.reduce((a, {idEvent, attendeeTypeId, apiKey}) => ({ ...a, [idEvent]: [apiKey,attendeeTypeId]}), {}) : null
  }

  const bodyRequiredLength = formFound?.bodyForm?.filter(
    (fields) => fields.required === true
  ).length

  const requiredDni = formFound?.bodyForm?.filter(
    (c) => c.strapi_component === 'form.dni'
  ).length
  const requiredColegio = formFound?.bodyForm?.filter(
    (c) => c.strapi_component === 'form.colegios-jpa'
  ).length
  const requiredAnioEgreso = formFound?.bodyForm?.filter(
    (c) => c.strapi_component === 'form.anio-egreso'
  ).length
  const requiredMultipleCareer = formFound?.bodyForm?.filter(
    (c) => c.strapi_component === 'form.multiple-career'
  ).length
  const requiredUbicacion = formFound?.bodyForm?.filter(
    (c) => c.strapi_component === 'form.ubicacion'
  ).length
  const requiredSelectType = formFound?.bodyForm?.filter(
    (c) => c.strapi_component === 'form.select-type'
  ).length

  useEffect(() => {
    if (
      values.name?.length > 2 &&
      validateEmail(values.email) &&
      validatePhoneNumber(values.phone) &&
      validateContent(requiredDni, valuesRequired.dni) &&
      validateContent(requiredColegio, valuesRequired.colegio) &&
      validateContent(requiredAnioEgreso, valuesRequired.anioEgreso) &&
      validateContent(requiredMultipleCareer, valuesRequired.multipleCareer) &&
      validateContent(requiredUbicacion, valuesRequired.ubicacion) &&
      validateContent(requiredSelectType, valuesRequired.selectType) &&
      validateForm(
        valuesRequired.content,
        formFound?.bodyForm?.length,
        bodyRequiredLength,
        formFound?.event,
      )
    ) {
      return setValidated(true)
    } else {
      return setValidated(false)
    }
  }, [values, selectedCareers]) // Se ejecuta cuando existe un cambio en cualquiera de estos parametros

  const handleSubmit = async (event) => {
    const formulario = event.currentTarget
    setLoading(true)
    event.preventDefault()

    delete valuesRequired.content.event

    if (validated){
      try {
        const sendForm = await postForm(valuesRequired)
        
        if (sendForm.status) {
          // Correct success form
          setLoading(false)
          formulario.reset()
          reset()

          if (formFound?.event.length !== 0) { 
            Swal.fire({
              title: '¡Te registraste con exito!',
              text: `Revisá tu email ${valuesRequired.email}. Estás a un paso de vivir la experiencia de estudiar en la Católica`,
              icon: 'success',
              timer: 4500, // Automatic closing
              didDestroy: () => {
                formFound.redirectFormPage &&
                  navigate(`/${formFound.redirectFormPage.slug}`);
              }
            })
          } else {
            Swal.fire({
              title: 'Genial!',
              text: 'Formulario enviado correctamente',
              icon: 'success',
              timer: 4500, // Automatic closing
              timerProgressBar: true,
              didDestroy: () => {
                formFound.redirectFormPage &&
                  navigate(`/${formFound.redirectFormPage.slug}`);
              }
            })
          }
          return

        } else {
          // Error sending form
          setLoading(false)
          // {formFound?.event.length !== 0 ? 
          //   Swal.fire(
          //     '¡Te registraste con exito!',
          //     `Revisá tu email ${valuesRequired.email}. Estás a un paso de vivir la experiencia de estudiar en la Católica`,
          //     'success'
          //   )
          //   :
          //   Swal.fire(
          //     'Genial!',
          //     'DOS Formulario enviado correctamente',
          //     'success'
          //   )
          // }

          Swal.fire({
            title: 'Oops...',
            text: 'Error al enviar el formulario, intente nuevamente!',
            icon: 'error',
            timer: 4500, // Automatic closing
          })

          setTimeout(() => {
            location.reload();
          }, 4500);

          return
        }

      } catch (error) {
        // Error sending form 
        setLoading(false)
        // {formFound?.event.length !== 0 ? 
        //   Swal.fire(
        //     '¡Te registraste con exito!',
        //     `Revisá tu email ${valuesRequired.email}. Estás a un paso de vivir la experiencia de estudiar en la Católica`,
        //     'success'
        //   )
        //   :
        //   Swal.fire(
        //     'Genial!',
        //     'TRES Formulario enviado correctamente',
        //     'success'
        //   )
        // }

        Swal.fire({
          title: '¡Te registraste con exito!',
          text: `Revisá tu email ${valuesRequired.email}. Estás a un paso de vivir la experiencia de estudiar en la Católica`,
          icon: 'success',
          timer: 4500, // Automatic closing
          didDestroy: () => {
            formFound.redirectFormPage &&
              navigate(`/${formFound.redirectFormPage.slug}`);
          }
        })

        // Swal.fire({
        //   title: 'Oops...',
        //   text: 'Error de conexión al servidor, intente nuevamente más tarde!',
        //   icon: 'error',
        //   timer: 5000, // Automatic closing
        // })
  
        setTimeout(() => {
          location.reload();
        }, 4500);

        reset()
        formulario.reset()
        return
      }
    }

  }

  const FormContact = (
    <Form
      noValidate
      onSubmit={handleSubmit}
      validated={validated}
      autoComplete="off"
      id="form"
    >

      {/* Event - JPA */}
      {formFound?.event?.map((c, i) => {
        if (i !== 0 && eventName !== c.name) {
          eventName = formFound?.event[i-1].name 
          count += 1
          
          if (eventName === c.name) {
            count -= 1
          }
        } 
       
        return (
          <div key={`event-${i}`} className={ eventName === c.name ? "" : 'mt-4'}>
            {/* {i !== 0 && eventName !== c.name && <hr className='bg-light'/>} - White Line */}
            <Form.Row key={'form-event-' + i} className={(count % 2) === 0 ? "form-event-bg pt-3 pl-2" : "pt-1 pl-2"}>
              <Col lg={12} xs={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    {eventName !== c.name && <h3 className='overflow-hidden'><Badge bg="light" className='text-left' style={{whiteSpace: "normal"}}>{c.name}</Badge></h3>}
                  </Col>
                  <Col lg={6} xs={12}>
                    {c.BuildingName && 
                      <div className="d-flex justify-content-end mr-4 mt-2 event-location">
                        <Link to={c.BuildingUrl ? c.BuildingUrl : null} target="_blank">
                          <h5 className="location-card-title font-weight-bolder">
                            <FontAwesomeIcon
                              icon={['fas', 'map-marker-alt']}
                              size="1x"
                              className="mr-2"
                            />
                            {c.BuildingName}
                          </h5>
                        </Link>
                      </div>
                    }
                  </Col>
                  <Col className='pl-4' lg={6} xs={12}>
                    <br/>
                    <Form.Label>
                      <ReactMarkdown source={c.description} />
                    </Form.Label>
                  </Col>
                  <Col className='pt-3 pl-4' lg={6} xs={12}>
                    <Form.Group controlId={'validationCustom'}>
                      {c.Items.map((op,i) => (
                        <div className='d-flex'>
                          <Form.Check 
                            type="switch"  // switch  checkbox
                            key={`switch-${c.idEvent}-${i}-${op.name}`}
                            className='checkbox-form-label form-check'
                            id={`${op.name}`}
                            label={op.name}
                            name={`${c.idEvent}`}
                            onClick={(event) => handleInputChange(event, true)}
                            // value: Convierto en un string porque sino al pasarlo al useForm no se visualizaban los objetos
                            value={JSON.stringify({eventtia: op.value, wiseCode: op.wiseCode})} 
                            feedback="Seleccione por lo menos uno"
                            isInvalid={values.event && !Object.values(values.event).length}
                          />
                          {op.tooltipItem && 
                            <div
                              className='tooltipContainer'
                              onMouseEnter={() => setShowTooltip(true)}
                              onMouseLeave={() => setShowTooltip(false)}
                            >
                              <FontAwesomeIcon
                                icon={['fas', 'fa-circle-info']}
                                size="1x"
                                className="mr-2 ml-2"
                              />
                              {showTooltip && <span className="tooltipItem">{op.tooltipItem}</span>}
                            </div>
                          }
                        </div>
                      ))}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Form.Row>
          </div>
        )           
      })}
      
      {/* White Line para separar Evento del Registro de Datos del Formulario */}
      {/* Agrego el segundo titulo para el Regitro de Datos */}
      {formFound?.event.length !== 0 && 
        <hr 
          className='form-line' 
          style={{ backgroundColor: `${theme === 'dark' ? '#292929' : 'white' }`}}
        />
      }
      {formFound?.event.length !== 0 && 
        <h2 className="font-weight-bold secondary-title-form">2º Completá tus datos</h2>
      }

      <Form.Row>
        {/* Name */}
        <Col lg={4} xs={12}>
          <Form.Group controlId={'validationCustom'}>
            <Form.Label>{formFound?.name?.name + ' *'}</Form.Label>
            <Form.Control
              name="name"
              type="text"
              minLength={2}
              maxLength={30}
              autoComplete="off"
              required
              placeholder={formFound?.name?.placeholder}
              onChange={handleInputChange}
              isValid={values.name?.length > 2}
              isInvalid={values.name?.length > 0 && values.name?.length <= 2}
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un dato por favor
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {/* email */}
        <Col lg={4} xs={12}>
          <Form.Group controlId={'validationCustom'}>
            <Form.Label>{formFound?.email?.name + ' *'}</Form.Label>
            <Form.Control
              name="email"
              type="email"
              required
              placeholder={formFound?.email?.placeholder}
              onChange={handleInputChange}
              isValid={validateEmail(values.email)}
              isInvalid={
                values.email?.length > 0 && !validateEmail(values.email)
              }
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un mail válido por favor
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {/* phone */}
        <Col lg={4} xs={12}>
          <Form.Group controlId={'validationCustom'}>
            <Form.Label>{formFound?.phone?.name + ' *'}</Form.Label>
            <div className='d-flex'>
              <PhoneInput
                autoComplete="off"
                international
                className="custom-phone-input phone-num"
                defaultCountry="AR"
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
              <Form.Control
                name='phone'
                type="text"
                className='phone-input'
                value={phonePais === phoneNumber ? valuesRequired.phone : phoneNumber}
                onChange={handleInputChange}
                autoComplete="off"
                required
                isValid={validatePhoneNumber(valuesRequired.phone)}
                isInvalid={
                  values.phone?.length > 2 && !validatePhoneNumber(valuesRequired.phone)
                }
              />
            </div>
          </Form.Group>
        </Col>
        
        {/* BodyForm */}
        {formFound?.bodyForm?.map((c, i) => {
          if (c.strapi_component === 'form.text') {
            // Text ***********************
            return (
              <Col lg={6} xs={12} key={'form-bodyform-' + i}>
                <Form.Group controlId={'validationCustom' + i}>
                  <Form.Label>
                    {c.required ? c.name + ' *' : c.name + ' (opcional)'}
                  </Form.Label>
                  <Form.Control
                    name={!c.required ? '_' + c.name : c.name}
                    type="text"
                    autoComplete="off"
                    minLength={c.required ? 3 : 0}
                    required={c.required}
                    placeholder={c.placeholder}
                    onChange={handleInputChange}
                    isValid={
                      (c.required &&
                        valuesRequired.content[c.name] !== undefined &&
                        valuesRequired.content[c.name].length > 2) ||
                      (!c.required &&
                        valuesRequired.content['_' + c.name] !== undefined &&
                        valuesRequired.content['_' + c.name].length > 2)
                    }
                    isInvalid={
                      c.required &&
                      valuesRequired.content[c.name]?.length > 0 &&
                      valuesRequired.content[c.name]?.length <= 2
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Ingrese un {c.name} por favor
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          } else if (c.strapi_component === 'form.select') {
            return (
              // Select ***********************
              <Col lg={6} xs={12} key={'form-bodyform-' + i}>
                <Form.Group controlId={'validationCustom' + i}>
                  <Form.Label>
                    {c.required ? c.name + ' *' : c.name + ' (opcional)'}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name={c.name}
                    onChange={handleInputChange}
                    required={c.required}
                    defaultValue={'-1'}
                    className="select-contact-form"
                    isValid={
                      c.required && valuesRequired.content[c.name] !== undefined
                    }
                  >
                    <option value="-1" disabled>
                      {c.placeholder}
                    </option>
                    {c.dropdownOptions.map((e, i) => (
                      <option value={e.option} key={'key_' + i + c.name}>
                        {e.option}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Seleccione {c.name} por favor
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          } else if (c.strapi_component === 'form.select-type') {
            return (
              // SelectType ***********************
              <Col lg={6} xs={12} key={'form-bodyform-' + i}>
                <Form.Group controlId={'validationCustom' + i}>
                  <Form.Label>
                    {c.required ? c.name + ' *' : c.name + ' (opcional)'}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name={'selectType'}
                    onChange={handleInputChange}
                    required={c.required}
                    defaultValue={'-1'}
                    className="select-contact-form"
                    isValid={
                      c.required && valuesRequired.selectType !== undefined
                    }
                  >
                    <option value="-1" disabled>
                      {c.placeholder}
                    </option>
                    {c.dropdownOptionsType.map((e, i) => (
                      <option value={e.interest_type?.option} key={'key_' + i + c.name}>
                        {e.interest_type?.option}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Seleccione {c.name} por favor
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          } else if (c.strapi_component === 'form.text-area') {
            return (
              // TextArea ***********************
              <Col lg={12} xs={12} key={'form-bodyform-' + i}>
                <Form.Group controlId={'validationCustom' + i}>
                  <Form.Label>
                    {c.required ? c.name + ' *' : c.name + ' (opcional)'}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name={!c.required ? '_' + c.name : c.name}
                    required={c.required}
                    onChange={handleInputChange}
                    placeholder={c.placeholder}
                    style={{ height: '80px' }}
                    className="text-black"
                    minLength={c.required ? 3 : 0}
                    maxLength="300"
                    isValid={
                      (c.required &&
                        valuesRequired.content[c.name] !== undefined &&
                        valuesRequired.content[c.name]?.length > 2) ||
                      (!c.required &&
                        valuesRequired.content['_' + c.name] !== undefined &&
                        valuesRequired.content['_' + c.name]?.length > 2)
                    }
                    isInvalid={
                      c.required &&
                      valuesRequired.content[c.name]?.length > 0 &&
                      valuesRequired.content[c.name]?.length <= 2
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Cuéntanos un poco más por favor.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          } else if (c.strapi_component === 'form.colegios-jpa') {
            return (
              // Colegios in JPA
              <Col lg={6} xs={12} key={'form-bodyform-' + i}>
                <Form.Group>
                  <Form.Label>{c.name + ' *'}</Form.Label>
                  <input 
                    className={`form form-control ${(values.colegio) ? ((values.colegio.length > 3) ? 'is-valid' : 'is-invalid'):('')}`} 
                    list="listColegios" 
                    name="colegio" 
                    onChange={handleInputChange}
                  />
                  <datalist id="listColegios" className='form'>
                    {colegiosList.map((e, i) => {
                      return (
                        <option
                          value={`${e.nombre}`}
                          key={`${e.nombre}`}
                        >
                        </option>
                      );
                    })}
                  </datalist>
                </Form.Group>
              </Col>
            )
          } else if (c.strapi_component === 'form.dni') {
            return (
              // DNI
              <Col lg={6} xs={12} key={'form-bodyform-' + i}>
                <Form.Group>
                  <Form.Label>{c.name + ' *'}</Form.Label>
                  <Form.Control
                    name={'dni'}
                    type="text"
                    required={true}
                    onChange={handleInputChange}
                    placeholder={c.placeholder}
                    className="text-black"
                    minLength={7}
                    maxLength={9}
                    isValid={
                      values.dni?.length >= 7 && values.dni?.length <= 9 
                    }
                    isInvalid={
                      values.dni?.length > 9 || values.dni?.length < 7
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Complete con un DNI correcto
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          } else if (c.strapi_component === 'form.ubicacion') {
            return (
              // Select Sede Universitaria ***********************
              <Col lg={6} xs={12} key={'form-bodyform-' + i}>
                <Form.Group controlId={'validationCustom' + i}>
                  <Form.Label>
                    {c.name + ' *'}
                  </Form.Label>
                  <Form.Control
                    name={'ubicacion'}
                    as="select"
                    onChange={handleInputChange}
                    defaultValue={'-1'}
                    className="select-contact-form"
                    isValid={
                      c.required && valuesRequired.content[c.name] !== undefined
                    }
                  >
                    <option value="-1" disabled>
                      {c.placeholder}
                    </option>
                    <option value="Córdoba">
                      {"Córdoba"}
                    </option>
                    <option value="UCC Rio Cuarto">
                      {"UCC Rio Cuarto"}
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Seleccione {c.name} por favor
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          } else if (c.strapi_component === 'form.multiple-career') {
            return (
              // Selector multiples Carreras
              <Col lg={12} xs={12} key={'form-bodyform-' + i}>
                <Form.Group>
                  <Form.Label>{c.name + ' *'}</Form.Label>
                  <Autocomplete
                    name={'careers'}
                    multiple
                    id="checkboxes-tags-demo"
                    className='autocomplete-component'
                    options={ubicacionExists ? filteredCareers : carrerasList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.nombre}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.nombre}
                      </li>
                    )}
                    value={selectedCareers}
                    onChange={handleOptionChange}
                    style={{ width: 500 }}
                    noOptionsText="Es necesario seleccionar el lugar donde querés cursar"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Busca tu carrera" />
                    )}
                  />
                </Form.Group>
              </Col>
            )
          } else if (c.strapi_component === 'form.anio-egreso') {
            return (
              // Año Egreso JPA
              <Col lg={6} xs={12} key={'form-bodyform-' + i}>
                <Form.Group>
                  <Form.Label>{c.name}</Form.Label>
                  <Form.Control
                    as="select"
                    name={'anioEgreso'}
                    onChange={handleInputChange}
                    required={true}
                    defaultValue={'-1'}
                    className="form-control"
                    isValid={
                      valuesRequired.anioEgreso !== undefined
                    }
                  >
                    <option value="-1" disabled>
                      {c.placeholder}
                    </option>
                    <option value={new Date().getFullYear() + 2}>
                      {new Date().getFullYear() + 2}
                    </option>
                    <option value={new Date().getFullYear() + 1}>
                      {new Date().getFullYear() + 1}
                    </option>
                    <option value={new Date().getFullYear()}>
                      {new Date().getFullYear()}
                    </option>
                    <option value={new Date().getFullYear() - 1}>
                      {new Date().getFullYear() - 1}
                    </option>
                    <option value={new Date().getFullYear() - 2}>
                      {new Date().getFullYear() - 2}
                    </option>
                    <option value={new Date().getFullYear() - 3}>
                      {new Date().getFullYear() - 3}
                    </option>
                    <option value={2000}>
                      {'Egresado en otro año'}
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Seleccione {c.name} por favor
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          }
        })}
      </Form.Row>

      {/* Submit */}
      <Form.Row>
        <Col lg={12} xs={12} key={'form-submit'}>
          <Form.Group>
            <div className="d-flex justify-content-end align-items-center pt-1">
              <div className="">
                <button
                  className=" rounded-pill border btn btn-sm btn-info from-background-color px-3 "
                  type="submit"
                  style={{
                    cursor: !validated || loading ? 'not-allowed' : 'pointer'
                  }}
                  disabled={!validated || loading}
                >
                  {!loading ? (
                    formFound?.event.length !== 0 ? 
                      <span className="px-2">Finalizar y enviar</span>
                      :
                      <span className="px-2">Enviar</span>
                  ) : (
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="pr-2">Enviando</span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </Form.Group>
        </Col>
      </Form.Row>
    </Form>
  )
  return (
    <div className="w-100 background-lightblue mb-5 container position-relative">
      <div className="container text-white pt-4 pb-3">
        {formFound?.event.length !== 0 ?
          <h2 className="font-weight-bold">{formFound.nameEventForm ? formFound.nameEventForm : "1º Seleccioná la charla de tu interés"}</h2>
          :
          <h2 className="font-weight-bold">{title}</h2>
        }
        {FormContact}
      </div>
    </div>
  )
}

Formulario.propTypes = {
  idForm: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  receiverGeneral: PropTypes.string,
  description: PropTypes.string
}
